import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { deleteAuthorizedAccount } from 'Fire/firebaseActions'
import useWindowSize from 'Hooks/useWindowSize'
import ReauthPopup from 'Components/ReauthPopup'
import PopupMenu from 'Components/PopupMenu'
import MenuItem from 'Components/MenuItem'
import HelpTooltip from 'Components/HelpTooltip'
import LazyImage from 'Components/LazyImage'
import TrashIcon from 'Images/icons/trash.svg'
import BasicSourceIcon from 'Images/icons/source-outline.svg'
import AdvancedSourceIcon from 'Images/icons/advanced.svg'
import RefreshIcon from 'Images/icons/refresh.svg'
import PlusIcon from 'Images/icons/plus.svg'
import FacebookIcon from 'Images/icons/facebook.svg'
import MetaIcon from 'Images/icons/meta.svg'
import styles from './Source.module.scss'

export default function Source({
  account: {
    id,
    type,
    accountType,
    label,
    proInstagramAccounts,
    instagramUserId,
    facebookUserId,
    reauthorize,
    reasonForReauth,
    expiresOn,
    profilePictureUrl,
  },
  first,
  last,
}) {
  const [showReauthPopup, setShowReauthPopup] = useState(false)
  const windowSize = useWindowSize()
  const isAdvancedSource = type.includes('instagramAdvanced')
  const isLegacySource = type.includes('Legacy')

  let typeLabelEl = isAdvancedSource ? (
    <>
      <AdvancedSourceIcon className={styles.icon} />
      Advanced
    </>
  ) : (
    <>
      <BasicSourceIcon className={styles.icon} />
      Basic
    </>
  )
  if (isLegacySource) {
    typeLabelEl = (
      <HelpTooltip
        width={290}
        className={styles.reauth_text}
        triggerText={<>Legacy</>}
        persistent
      >
        <div className={styles.tooltip}>
          <h1>Legacy Source</h1>
          Reauthorize this source to update for the upcoming Instagram API
          change.{' '}
          <a
            href="https://behold.so/updates/basic-display-api-deprecated"
            target="_blank"
          >
            Learn more
          </a>
          .
        </div>
      </HelpTooltip>
    )
  }
  const expires =
    accountType === 'FACEBOOK'
      ? new Date(expiresOn.seconds * 1000).toLocaleDateString()
      : 'Auto-renew'

  /*
   *   Menu items
   */
  const menuItemEls = useMemo(
    () => (
      <>
        {instagramUserId && (
          <MenuItem
            to={`/add-feed/user/source/${facebookUserId ?? ''}_${instagramUserId}/type`}
          >
            <PlusIcon className={styles.menu_icon} /> New feed
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowReauthPopup(true)}>
          <RefreshIcon className={styles.menu_icon} /> Reauthorize
        </MenuItem>
        <MenuItem
          confirm
          confirmText={'Confirm Delete'}
          color="red"
          onClick={() => deleteAuthorizedAccount(id)}
        >
          <TrashIcon /> Remove source
        </MenuItem>
      </>
    ),
    [accountType, id],
  )

  let reauthMessage = (
    <p>
      This source is no longer connected. Reauthorize to start fetching fresh
      content again.
    </p>
  )

  switch (reasonForReauth) {
    case 'PASSWORD_CHANGE':
      reauthMessage = (
        <p>
          The password for this source was changed or the session was cleared
          for security reasons. Reauthorize to start fetching fresh content
          again.
        </p>
      )
      break

    case 'PRIVATE':
      reauthMessage = (
        <p>
          This Instagram account was set to private. Reauthorize to start
          fetching fresh content again.
        </p>
      )
      break
  }

  let proAccountEls =
    proInstagramAccounts && proInstagramAccounts !== 'LOADING' ? (
      Object.values(proInstagramAccounts)
        .filter((item) => !!item?.username)
        .sort((a, b) => {
          return a.username.localeCompare(b.username)
        })
        .map((account) => (
          <div className={styles.pro_account} key={account.instagramUserId}>
            {account.profilePictureUrl && (
              <LazyImage
                height={24}
                width={24}
                className={styles.profile_picture}
                src={account.profilePictureUrl}
              />
            )}
            <div className={classnames(styles.cell, styles.pro_username)}>
              @{account.username}
            </div>
          </div>
        ))
    ) : (
      <div className={styles.pro_account}>
        <div className={styles.cell}>
          <div className={styles.pro_account__loader}>
            <l-squircle
              speed={0.75}
              size={15}
              stroke={2}
              color="var(--color-text-light)"
            ></l-squircle>
          </div>
          Fetching associated Instagram accounts
        </div>
      </div>
    )

  if (
    proInstagramAccounts &&
    proInstagramAccounts !== 'LOADING' &&
    Object.keys(proInstagramAccounts).length === 0
  ) {
    proAccountEls = (
      <div className={styles.pro_account}>
        <div className={styles.cell}>
          No associated Instagram accounts.{' '}
          <a
            href="https://behold.so/docs/basic-vs-advanced-sources/#adding-an-advanced-source"
            target="_blank"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </div>
      </div>
    )
  }

  const expiresEl =
    accountType === 'FACEBOOK' ? (
      <button
        className={styles.expires_date}
        title="Click to reauthorize"
        onClick={() => setShowReauthPopup(true)}
      >
        {expires}
      </button>
    ) : (
      expires
    )

  const reauthEl = (
    <div className={classnames(styles.reauth_message)}>
      <HelpTooltip
        width={290}
        className={styles.reauth_text}
        triggerText="Inactive"
      >
        <div className={styles.tooltip}>
          <h1>Reauthorization Required</h1>
          {reauthMessage}
        </div>
      </HelpTooltip>
      <button
        className={styles.reauth_button}
        onClick={() => setShowReauthPopup(true)}
      >
        Reauthorize
      </button>
    </div>
  )

  const legacyUpdateEl = (
    <div className={classnames(styles.reauth_message)}>
      <div className={styles.reauth_text}>December</div>
      <button
        className={styles.reauth_button}
        onClick={() => setShowReauthPopup(true)}
      >
        Reauthorize
      </button>
    </div>
  )

  let accountTypeLabel = 'Instagram Account'

  if (isAdvancedSource && accountType === 'SYSTEM_USER') {
    accountTypeLabel = 'Business Portfolio'
  }
  if (isAdvancedSource && accountType === 'FACEBOOK') {
    accountTypeLabel = 'Facebook User'
  }

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.reauthorize]: reauthorize,
          [styles.first]: first,
          [styles.last]: last,
          [styles.advanced]: isAdvancedSource,
        })}
      >
        <div className={styles.inner}>
          <div className={classnames(styles.cell, styles.username)}>
            {profilePictureUrl && !isAdvancedSource && (
              <LazyImage
                height={24}
                width={24}
                className={styles.profile_picture}
                src={profilePictureUrl}
              />
            )}
            {!profilePictureUrl && !isAdvancedSource && (
              <div className={styles.profile_picture} />
            )}
            {isAdvancedSource && accountType === 'FACEBOOK' && (
              <div className={styles.advanced_dot}>
                <FacebookIcon />
              </div>
            )}
            {isAdvancedSource && accountType === 'SYSTEM_USER' && (
              <div className={styles.advanced_dot}>
                <MetaIcon />
              </div>
            )}
            <div className={styles.cell__inner}>
              <HelpTooltip
                width={150}
                xAlign="center"
                yAlign="top"
                className={styles.reauth_text}
                triggerText={
                  <>
                    {type.includes('Basic') && '@'}
                    {label}
                  </>
                }
                showIcon={false}
              >
                <div className={styles.tooltip_small}>{accountTypeLabel}</div>
              </HelpTooltip>
            </div>
          </div>

          <div className={classnames(styles.cell, styles.type)}>
            {typeLabelEl}
          </div>

          <div className={classnames(styles.cell)}>
            {!isLegacySource && !reauthorize && expiresEl}
            {!isLegacySource && reauthorize && reauthEl}
            {isLegacySource && legacyUpdateEl}
          </div>

          <div className={classnames(styles.cell, styles.menu_wrapper)}>
            <PopupMenu
              className={styles.menu}
              containerId={windowSize.width > 600 ? 'content' : 'root'}
              width={180}
              offset={2}
              yAlign="bottom"
            >
              {menuItemEls}
            </PopupMenu>
          </div>
          {isAdvancedSource && (
            <div className={styles.pro_accounts}>{proAccountEls}</div>
          )}
        </div>
      </div>

      {showReauthPopup && (
        <ReauthPopup
          sourceId={id}
          onRequestClose={() => setShowReauthPopup(false)}
          redirectTo="sources"
        />
      )}
    </>
  )
}

Source.propTypes = {
  account: PropTypes.object,
  first: PropTypes.bool,
  last: PropTypes.bool,
}

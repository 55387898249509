import { auth } from 'Fire/firebaseInit'
import { makeFetchUrl } from 'Helpers/utils'
import blobToDataUrl from 'Helpers/blobToDataUrl'

export async function getInsights(username, sourceId) {
  const token = await auth.currentUser.getIdToken()
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/insights`, {
      username,
      sourceId,
    }),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  return res.json()
}

export async function findHashtag(sourceId, instagramUserId, hashtag) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/hashtags/search`, {
      sourceId,
      instagramUserId,
      hashtag,
    }),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

export async function deleteCurrentUser() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/users/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

export async function decipherSecret(secret, signal) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/decipher`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ secret }),
      signal,
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function addApiSecret() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/api/add`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function deleteApiSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/api/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function addWebhookSecret() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/webhooks/add`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function deleteWebhookSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/webhooks/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function setActiveWebhookSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(
      `${process.env.SERVICES_URL}/private/secrets/webhooks/activate`,
    ),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

/**
 *   @param {{image: Blob, uploadPath: string, bucket: string, maxWidth?: number, maxHeight?: number, quality?: number, dbPath?:string, dbField?:string}} upload
 */
export async function uploadImageToBucket(upload) {
  const dataUrl = await blobToDataUrl(upload.image)
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/images/upload`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        base64Source: dataUrl,
        ...upload,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {{imagePath: string, bucket: string, dbPath?:string, dbField?:string}} deletion
 */
export async function deleteImageInBucket({
  imagePath,
  bucket,
  dbPath = '',
  dbField = null,
}) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/images/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imagePath,
        bucket,
        dbPath,
        dbField,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {string} email
 *   @param {getUidFromEmail} boolean
 */
export async function setUserEmail(email, getUidFromEmail = false) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/users/setEmail`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        getUidFromEmail,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {string} email
 */
export async function sendVerificationEmail(email) {
  const lastSent = sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL') || 0
  sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', Date.now())

  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(
      `${process.env.SERVICES_URL}/private/users/sendVerificationEmail`,
    ),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', lastSent)
    console.log(json.message)
  }

  return json
}

import PropTypes from 'prop-types'
import { useState, useRef, useMemo, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { getFeedPoster } from 'Helpers/utils'
import { clearFeedCache } from 'Helpers/instaApi'
import { updateInstagramFeed } from 'Fire/firebaseActions'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import useTimers from 'Hooks/useTimers'
import useHotkey from 'Hooks/useHotkey'
import SmartButton from './SmartButton'
import ReauthPopup from 'Components/ReauthPopup'
import HelpTooltip from 'Components/HelpTooltip'
import PopupMenu from 'Components/PopupMenu'
import LazyImage from 'Components/LazyImage'
import Video from 'Components/Video'
import ControlledInput from 'Components/ControlledInput'
import FeedMenu from 'Components/FeedMenu'
import FeedIcon from 'Images/icons/feed-outline.svg'
import APIcon from 'Images/icons/json.svg'
import KlaviyoIcon from 'Images/klaviyo-outline.svg'
import BasicIcon from 'Images/icons/source-outline.svg'
import AdvancedIcon from 'Images/icons/advanced.svg'
import Eyecon from 'Images/icons/eye-outline.svg'
import HashIcon from 'Images/icons/hash.svg'
import AccountIcon from 'Images/icons/user.svg'
import styles from './FeedCard.module.scss'

export default function FeedCard({
  feed,
  reauth = false,
  reasonForReauth = null,
  first = false,
  last = false,
}) {
  const navigate = useNavigate()
  const { st } = useTimers()
  const { setFeedTitle } = useSlice('setFeedTitle')
  const { username, type, subtype, api, source, posts } = feed
  const [showReauthPopup, setShowReauthPopup] = useState(false)
  const [pausedButtonIsLoading, setPausedButtonIsLoading] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const feedTitleRef = useRef(feed.title)
  const titleInputRef = useRef()
  const usage = feed?.usage
  const windowSize = useWindowSize()
  const [forceCloseMenu, setForceCloseMenu] = useState(false)
  const memoizedPosts = useMemo(() => posts, [posts?.length])
  const poster = getFeedPoster(memoizedPosts)

  function openFeed() {
    navigate(`/feeds/${feed.id}`)
  }

  async function changeTitle(val) {
    setEditingTitle(false)
    if (val === feedTitleRef.current) return
    feedTitleRef.current = val

    try {
      setFeedTitle(feed.id, val)
      await updateInstagramFeed(feed.id, { title: val })
    } catch (error) {
      console.log(error)
    }
  }

  useHotkey(
    'Enter',
    () => changeTitle(titleInputRef.current?.value || feedTitleRef.current),
    editingTitle,
  )

  useEffect(() => {
    if (!feed.isUpdating && pausedButtonIsLoading) {
      setPausedButtonIsLoading(false)
    }
  }, [feed])

  function editTitle() {
    setEditingTitle(true)
    setForceCloseMenu(true)
    st(() => titleInputRef.current?.focus(), 50)
  }

  const isLoading =
    feed.isUpdating &&
    !feed.pausedByOverage &&
    (feed.source === 'HASHTAG' ? !!feed.hashtags?.length : true)

  const posterEl = poster ? (
    poster.mediaUrlType === 'VIDEO' ? (
      <Video className={styles.video} src={poster.mediaUrl} currentTime={1} />
    ) : (
      <LazyImage
        key={poster.permalink}
        className={styles.photo}
        src={
          poster.sizes?.medium?.mediaUrl ||
          poster.thumbnailUrl ||
          poster.mediaUrl
        }
      />
    )
  ) : null

  let typeIcon =
    type === 'WIDGET' ? (
      <>
        <FeedIcon /> Widget
      </>
    ) : (
      <>
        <APIcon /> JSON
      </>
    )

  if (subtype === 'KLAVIYO') {
    typeIcon = (
      <>
        <KlaviyoIcon /> Klaviyo{' '}
      </>
    )
  }

  const apiText =
    api === 'GRAPH' ? (
      <>
        <AdvancedIcon /> Advanced Source
      </>
    ) : (
      <>
        <BasicIcon /> Basic Source
      </>
    )

  let reauthMessage = (
    <>
      <p>
        <b>Disconnected</b>
      </p>
      <p>
        <button onClick={() => setShowReauthPopup(true)}>Reauthorize</button>
      </p>
    </>
  )

  switch (reasonForReauth) {
    case 'PASSWORD_CHANGE':
      reauthMessage = (
        <>
          <p>
            <b>Disconnected</b>
          </p>
          <p>
            The password for this feed&apos;s source was changed or the access
            token was invalidated for security reasons.
          </p>
          <p>
            <button onClick={() => setShowReauthPopup(true)}>
              Reauthorize
            </button>
          </p>
        </>
      )
      break

    case 'PRIVATE':
      reauthMessage = (
        <>
          <p>
            <b>Disconnected</b>
          </p>
          <p>This Instagram account was set to private. </p>
          <p>
            <button onClick={() => setShowReauthPopup(true)}>
              Reauthorize
            </button>
          </p>
        </>
      )
      break
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles.loading]: isLoading,
        [styles.first]: first,
        [styles.last]: last,
      })}
      style={{
        '--icon-color': poster?.backgroundColor || '#eee',
      }}
    >
      {reauth && (
        <div
          className={styles.error}
          role="alert"
          aria-description={feed.title}
        >
          {reauthMessage}
        </div>
      )}

      {feed.inactive && (
        <div
          className={styles.error}
          role="alert"
          aria-description={feed.title}
        >
          <p>
            <b>Paused</b>
          </p>

          <p>
            Suspended because of extended inactivity. Click below to restart.
          </p>

          <p>
            <SmartButton
              isLoading={pausedButtonIsLoading}
              color="light_blue"
              size="medium"
              onClick={() => {
                setPausedButtonIsLoading(true)
                clearFeedCache(feed.id)
              }}
            >
              Reactivate
            </SmartButton>
          </p>
        </div>
      )}

      {feed.source === 'HASHTAG' && !feed.hashtags?.length && (
        <div
          className={styles.error}
          role="alert"
          aria-description={feed.title}
        >
          <p>
            <b>No hashtags selected</b>
          </p>

          <p>
            <SmartButton
              isLoading={pausedButtonIsLoading}
              color="light_blue"
              size="medium"
              to={`/feeds/${feed.id}/hashtags`}
            >
              Add hashtags
            </SmartButton>
          </p>
        </div>
      )}

      {!reauth && !feed.inactive && (
        <Link
          to={`/feeds/${feed.id}`}
          aria-labelledby={`title_${feed.id}`}
          className={styles.toggle_button}
        ></Link>
      )}

      <div className={styles.top}>
        <h3 className={styles.title} id={`title_${feed.id}`}>
          {editingTitle ? (
            <ControlledInput
              ref={titleInputRef}
              className={styles.title__input}
              value={feed.title}
              onChange={changeTitle}
              updateOnEnter={true}
              updateOn="blur"
              unstyled
            />
          ) : (
            <div className={styles.title_inner}>{feed.title}</div>
          )}
        </h3>
      </div>

      <div className={styles.menu}>
        <PopupMenu
          forceClose={forceCloseMenu}
          onClose={() => setForceCloseMenu(false)}
          containerId={windowSize.width > 600 ? 'content' : 'root'}
          yAlign="bottom"
          offset={5}
          width={170}
          color="white"
        >
          <FeedMenu feedId={feed.id} editTitleHandler={editTitle} />
        </PopupMenu>
      </div>

      <div className={styles.bottom}>
        <HelpTooltip
          className={styles.stat}
          showIcon={false}
          delay={30}
          openOnClick={false}
          onClick={openFeed}
          xAlign="left"
          width={105}
          tabIndex={-1}
          triggerText={
            <>
              <Eyecon /> {usage ? usage.toLocaleString() : 0}
            </>
          }
        >
          <div className={styles.tooltip}>Monthly views</div>
        </HelpTooltip>
        <HelpTooltip
          className={styles.stat}
          showIcon={false}
          delay={30}
          openOnClick={false}
          onClick={openFeed}
          xAlign="left"
          width={95}
          tabIndex={-1}
          triggerText={typeIcon}
        >
          <div className={styles.tooltip}>Feed output</div>
        </HelpTooltip>
        <HelpTooltip
          className={styles.stat}
          showIcon={false}
          delay={30}
          openOnClick={false}
          onClick={openFeed}
          xAlign="left"
          width={95}
          tabIndex={-1}
          triggerText={apiText}
        >
          <div className={styles.tooltip}>Source type</div>
        </HelpTooltip>
        {source === 'HASHTAG' && (
          <HelpTooltip
            className={styles.stat}
            showIcon={false}
            delay={30}
            openOnClick={false}
            onClick={openFeed}
            xAlign="left"
            width={105}
            tabIndex={-1}
            triggerText={
              <>
                <HashIcon /> @{username}
              </>
            }
          >
            <div className={styles.tooltip}>Hashtag feed</div>
          </HelpTooltip>
        )}
        {source !== 'HASHTAG' && (
          <HelpTooltip
            className={styles.stat}
            showIcon={false}
            delay={30}
            openOnClick={false}
            onClick={openFeed}
            xAlign="left"
            width={85}
            tabIndex={-1}
            triggerText={
              <>
                <AccountIcon /> @{username}
              </>
            }
          >
            <div className={styles.tooltip}>User feed</div>
          </HelpTooltip>
        )}
      </div>

      <div className={styles.background_image}>
        <div className={styles.background_image__inner}>{posterEl}</div>
      </div>

      {isLoading && (
        <div className={styles.loader}>
          <l-squircle
            color="var(--color-algae)"
            stroke={2}
            size={24}
          ></l-squircle>
        </div>
      )}

      {showReauthPopup && (
        <ReauthPopup
          sourceId={feed.sourceId}
          onRequestClose={() => setShowReauthPopup(false)}
        />
      )}
    </div>
  )
}

FeedCard.propTypes = {
  feed: PropTypes.object,
  reauth: PropTypes.bool,
  reasonForReauth: PropTypes.string,
  first: PropTypes.bool,
  last: PropTypes.bool,
}

import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import md5 from 'md5'
import { Link, useMatch } from 'react-router-dom'
import classnames from 'classnames'
import { signOut } from 'firebase/auth'
import { isPopulated } from 'Helpers/utils'
import { auth } from 'Fire/firebaseInit'
import { useSlice } from 'State'
import { cancelAllListeners } from 'Components/FirebaseConnect'
import useWindowSize from 'Hooks/useWindowSize'
import Usage from 'Components/Usage'
import PopupMenu from 'Components/PopupMenu'
import MenuItem from 'Components/MenuItem'
import SidebarUpgrade from './SidebarUpgrade'
import Logo from 'Images/mark.svg'
import IconFeed from 'Images/icons/feed-outline.svg'
import IconSource from 'Images/icons/source-outline.svg'
import IconDownCaret from 'Images/icons/caret-down.svg'
import IconSupport from 'Images/icons/support-outline.svg'
import IconUpgrade from 'Images/icons/upgrade.svg'
import styles from './Sidebar.module.scss'

export default function Sidebar({ show = true }) {
  const windowSize = useWindowSize()
  const { user, authorizedAccounts, feeds } = useSlice(
    'user',
    'authorizedAccounts',
    'feeds',
  )
  const [photoIsLoaded, setPhotoIsLoaded] = useState(false)
  const [chatIsLive, setChatIsLive] = useState(false)

  const providerData = auth?.currentUser?.providerData
  const userPhoto = providerData.reduce((acc, curr) => {
    if (curr.userPhoto?.length > 0) {
      return curr.userPhoto
    }
    return acc
  }, '')
  const photoSrc =
    userPhoto ||
    `https://www.gravatar.com/avatar/${md5(
      user.email.toLowerCase(),
    )}.jpg?d=blank`

  let initials = ''
  const userName = providerData.reduce((acc, curr) => {
    if (curr.displayName?.length > 0) {
      return curr.displayName
    }
    return acc
  }, '')
  if (userName) {
    const namArr = userName.split(' ')
    const firstInitial = namArr.length > 0 ? namArr[0][0] : ''
    const secondInitial = namArr.length > 1 ? namArr[1][0] : namArr[0][1]
    initials = firstInitial + secondInitial
  } else {
    initials = user.email[0] + user.email[1]
  }

  const activeFeedsLength = isPopulated(feeds)
    ? Object.values(feeds).filter(
        (f) => !!f.created && authorizedAccounts?.[f.sourceId],
      ).length
    : 0

  function handlePhotoLoad() {
    setPhotoIsLoaded(true)
  }

  function toggleChat(evt) {
    setChatIsLive(evt === 'online' || evt.data === 'online' ? true : false)
  }

  function logOut() {
    cancelAllListeners()
    signOut(auth)
  }

  useEffect(() => {
    if (window.tidioChatApi) {
      window.tidioChatApi.on('setStatus', toggleChat)
      toggleChat(window.beholdTidioOnlineStatus || 'offline')
    } else {
      document.addEventListener('tidioChat-setStatus', toggleChat)
    }
  }, [])

  return windowSize.width > 600 && show ? (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <SmartLink to="/feeds" className={styles.logo} tabIndex={-1}>
          <Logo />
        </SmartLink>
        <SmartLink to="/feeds" include="" className={styles.nav__item}>
          <IconFeed />
          Feeds
          <div className={styles.count}>{activeFeedsLength}</div>
        </SmartLink>
        <SmartLink to="/sources" className={styles.nav__item}>
          <IconSource /> Sources
          <div className={styles.count}>
            {isPopulated(authorizedAccounts)
              ? Object.keys(authorizedAccounts).length
              : 0}
          </div>
        </SmartLink>
        <SmartLink to="/help" className={styles.nav__item}>
          <IconSupport /> Help{' '}
          <div
            className={classnames(styles.live_chat__indicator, {
              [styles.live_chat__online]: chatIsLive,
            })}
          />
        </SmartLink>
      </nav>
      {/* <hr />
      <button className={styles.notifications}>
        <IconNotification /> Notifications
      </button>
      <hr /> */}
      <Usage />
      {user.plan === 'Free' && <SidebarUpgrade />}
      <hr />

      <PopupMenu
        className={styles.account}
        fixed
        width={150}
        yAlign="bottom"
        offset={15}
        unstyled={true}
        icon={
          <>
            <div className={styles.avatar}>
              <img
                className={classnames(styles.photo, {
                  [styles.loaded]: photoIsLoaded,
                })}
                src={photoSrc}
                onLoad={handlePhotoLoad}
              />
              {initials}
            </div>
            <div className={styles.account__email}>{user.email}</div>
            <IconDownCaret />
          </>
        }
      >
        {user.plan !== 'Enterprise' && (
          <MenuItem to="/choose-plan">
            <IconUpgrade /> Upgrade
          </MenuItem>
        )}
        <MenuItem to="/account">Account</MenuItem>
        <MenuItem onClick={logOut} confirm>
          Sign Out
        </MenuItem>
      </PopupMenu>
    </div>
  ) : null
}

function SmartLink({
  loose,
  to,
  className,
  currentClass = styles.current,
  include = 'none',
  children,
  tabIndex,
}) {
  const matchPattern = loose ? to + '/*' : to
  const match = useMatch(matchPattern)
  const includeMatch = useMatch(include)

  return (
    <Link
      to={to}
      className={classnames(styles.link, className, {
        [currentClass]: !!match || !!includeMatch,
      })}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  )
}

SmartLink.propTypes = {
  loose: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
  currentClass: PropTypes.string,
  include: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  tabIndex: PropTypes.number,
}

import { useEffect, useRef } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { v1 as uuidv1 } from 'uuid'
import classNames from 'classnames'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import TransitionWrapper from 'Components/TransitionWrapper'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import AdvancedIcon from 'Images/icons/advanced.svg'
import BasicIcon from 'Images/icons/source-outline.svg'
import PlusIcon from 'Images/icons/plus.svg'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedSource() {
  const appState = useSlice('authorizedAccounts')
  const { sourceType } = useParams()
  const { st } = useTimers()
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)
  const animateSourcesRef = useRef(false)
  const sourcesContainerRef = useRef(null)

  function animove(url) {
    setIsLoaded(false)
    st(() => {
      navigate(url)
    }, 200)
  }

  useEffect(() => {
    st(() => {
      animateSourcesRef.current = true
    }, 1000)
  }, [])

  const igAccounts = Object.values(appState.authorizedAccounts)
    .filter((account) => account.id)
    .filter((account) => {
      return sourceType === 'hashtag' &&
        !account?.type?.includes('instagramAdvanced')
        ? false
        : true
    })
    .sort((a, b) => {
      if (a.proInstagramAccounts === 'LOADING') return 1
      if (b.proInstagramAccounts === 'LOADING') return -1
      return 0
    })
    .reduce((acc, curr) => {
      if (curr.type === 'instagramAdvancedLegacy') {
        if (
          curr.proInstagramAccounts === 'LOADING' ||
          !curr.facebookUserId ||
          !curr.accessToken
        ) {
          acc[curr.facebookUserId] = {
            loading: true,
            id: curr.id || uuidv1(),
          }
        } else {
          Object.values(curr.proInstagramAccounts).forEach((proAccount) => {
            acc[proAccount.instagramUserId + '_pro_legacy'] = {
              ...proAccount,
              api: 'GRAPH',
              parentSourceId: curr.id,
              accountType: curr.accountType,
              type: curr.type,
            }
          })
        }
        return acc
      } else if (curr.type === 'instagramAdvanced') {
        Object.values(curr.proInstagramAccounts).forEach((proAccount) => {
          acc[proAccount.instagramUserId + '_pro'] = {
            ...proAccount,
            api: 'GRAPH',
            parentSourceId: curr.id,
            accountType: curr.accountType,
            type: curr.type,
          }
        })
        return acc
      } else if (curr.type === 'instagramBasicLegacy') {
        return { ...acc, [curr.instagramUserId + '_legacy']: curr }
      } else {
        return { ...acc, [curr.instagramUserId]: curr }
      }
    }, {})

  const sourceEls = Object.values(igAccounts)
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    .sort((a, b) => {
      if (a.type.includes('Legacy')) return 1
      if (b.type.includes('Legacy')) return -1
      return 0
    })
    .map((account, i) => {
      let typeIcon = <BasicIcon />
      let typeLabel = 'Basic'

      switch (account.type) {
        case 'instagramAdvanced':
          typeIcon = <AdvancedIcon />
          typeLabel = 'Advanced - Business'
          if (account.accountType === 'FACEBOOK') {
            typeLabel = 'Advanced - Facebook'
          }
          break
        case 'instagramAdvancedLegacy':
          typeIcon = <AdvancedIcon />
          typeLabel = 'Legacy Advanced'
          break
        case 'instagramBasic':
          typeLabel = 'Basic'
          break
        case 'instagramBasicLegacy':
          typeLabel = 'Legacy Basic'
          break
      }

      return account.loading ? (
        <div className={styles.source_loading} key={account.id}>
          <div className={styles.source_loading__loader}>
            <l-zoomies
              size={80}
              color="var(--color-text-green)"
              speed={1.3}
            ></l-zoomies>
          </div>
          Processing new sources.
          <br />
          This shouldn&apos;t take long.
        </div>
      ) : (
        <TransitionWrapper
          key={account.instagramUserId + account.api}
          scale={animateSourcesRef.current}
          className={styles.source_button__container}
        >
          <button
            id={i === 0 ? 'skip-target' : ''}
            aria-label={`Advanced Instagram source: ${account.username}`}
            className={styles.source_button}
            onClick={() =>
              animove(`${account.parentSourceId ?? ''}_${account.id}/type`)
            }
          >
            <div className={styles.source_button__type}>{typeIcon}</div>
            <div className={styles.source_button__text}>
              <span className={styles.source_button__label}>{typeLabel}</span>@
              {account.username}{' '}
            </div>
            <CaretRightIcon className={styles.source_button__caret} />
          </button>
        </TransitionWrapper>
      )
    })

  const sourcesHaveOverflow = sourcesContainerRef.current
    ? sourcesContainerRef.current.offsetHeight <
      sourcesContainerRef.current.scrollHeight
    : false

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Pick a source for your feed
      </h1>
      {sourceType === 'hashtag' && (
        <div
          className={classNames(styles.subtitle, {
            [styles.loaded]: isLoaded,
          })}
        >
          Hashtag feeds require an
          <br />
          advanced Instagram source
        </div>
      )}
      <div
        className={classNames(styles.sources_container, {
          [styles.has_overflow]: sourcesHaveOverflow,
        })}
      >
        <div
          ref={sourcesContainerRef}
          className={classNames(styles.sources, {
            [styles.loaded]: isLoaded,
          })}
        >
          {sourceEls}
          <Link
            to={
              sourceType === 'hashtag'
                ? '/add-source/advanced-instagram'
                : '/add-source/basic-instagram'
            }
            state={{
              afterAuthRedirect: location.pathname,
            }}
            className={classNames(styles.new_source_button)}
          >
            <div className={styles.new_source_button__inner}>
              <PlusIcon />
              New {sourceType === 'hashtag' ? 'advanced ' : ''}source
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
